import React, {useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { get, times, capitalize, kebabCase } from 'lodash'
import Layout from '../components/theme/layout'
import PostCard from '../components/contentful/post-card'
import SEO from '../components/seo'
import StickyShare from '../components/sticky-share/sticky-share'
import styles from '../scss/blog-posts-page.scss'
import { blogUrl, blogPostCategoryLabels, blogPostCategoryDescriptions, findAncestorByClassNameInEventPath, buildBreadcrumbsItem, postUrl,
  collectionUrl, sendToKlaviyo } from "../helpers.js"
import { ALL_BLOG_CATEGORIES_LABEL } from "../constants.js"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import Card from 'react-bootstrap/Card'
import Carousel from "react-bootstrap/Carousel";
import { FaArrowRight } from 'react-icons/fa'

class BlogPostsPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    this.page = get(this.props, 'pageContext.page', 1)
    this.category = get(this.props, 'pageContext.category', ALL_BLOG_CATEGORIES_LABEL) || ALL_BLOG_CATEGORIES_LABEL
    this.pages = get(this.props, 'pageContext.pages', 1)
    this.posts = get(this.props, 'pageContext.posts', [])
    this.contentfulNoIndexBlogs = get(this.props, 'data.site.siteMetadata.contentfulNoIndexBlogs', [])
    this.featuredBlogPosts = get(this.props, 'data.featuredBlogPosts.edges', []) || []
    this.allCategories = get(this.props, 'pageContext.allCategories', [])
    const capitalizedCategory = capitalize(this.category)

    this.seo = {
      title: 'Learn More' + (this.category !== ALL_BLOG_CATEGORIES_LABEL ? ` About ${capitalizedCategory}` : '')  + ' | Honest Paws®',
      description:  'Learn how to take better care of your fur baby and improve their overall health.',
      children: []
    }

    this.state = {
      breadcrumbs_visible: false,
      btn_disabled: false,
      customer_email: '',
      filtersOpen: false,
      show_success_message: false
    }

    this.seo.paginationAttributes = {
      currentLink: blogUrl(this.category, this.page, this.siteUrl)
    }
    if (this.pages > 1 && this.page > 1 ) {
      this.seo.paginationAttributes.prevLink = blogUrl(this.category, this.page - 1, this.siteUrl)
    }
    if (this.pages > 1 && this.page < this.pages ) {
      this.seo.paginationAttributes.nextLink = blogUrl(this.category, this.page + 1, this.siteUrl)
    }

    if (this.contentfulNoIndexBlogs.indexOf(this.category) >= 0 ) {
      this.seo.disallowIndexing = true
    }

    this.handleFiltersButtonChange = this.handleFiltersButtonChange.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleBreadcrumbsTrigger = this.handleBreadcrumbsTrigger.bind(this)
    this.handleCustomerEmail = this.handleCustomerEmail.bind(this);
    this.subscribeToKlaviyoList = this.subscribeToKlaviyoList.bind(this);

    this.breadcrumbs = [
      buildBreadcrumbsItem('Blog', blogUrl(null, 1, this.props.location.origin), this.handleBreadcrumbsTrigger)
    ]
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  /**
   * Open/Closes filter button
   * @return {[type]}
   */
  handleFiltersButtonChange() {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    })
  }

  /**
   * Handles breadcrumbs box visualization
   * @param  {Event} e
   * @return {void}
   */
  handleBreadcrumbsTrigger(e) {
    this.setState({
      breadcrumbs_visible: !this.state.breadcrumbs_visible
    })
  }

  /**
   * Handles click event so we can close the cart when it a click is made outside it
   * @param  {event} e
   * @return {void}
   */
  handleDocumentClick(e) {
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['.btn-toggle-breadcrumbs', '.breadcrumbs-list-container']) && this.state.breadcrumbs_visible) {
      this.handleBreadcrumbsTrigger()
    }
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['filter-btn-group-container-inner']) && this.state.filtersOpen) {
      this.handleFiltersButtonChange()
    }
  }

  handleCustomerEmail(e) {
    this.setState({
      customer_email: e.target.value
    })
  }

  subscribeToKlaviyoList () {
    this.setState({
      btn_disabled: true
    })

    sendToKlaviyo('JBZMW4', this.state.customer_email, {source: 'Blog'}, (res) => {
      this.setState({
        show_success_message: true
      })
    }, (error) => {
      console.log(error)
    })
  }

  render() {
    const allCategories = [ALL_BLOG_CATEGORIES_LABEL].concat(this.allCategories)
    const prevLink = (this.pages > 1 && this.page > 1 ) ? blogUrl(this.category, this.page - 1) : null
    const nextLink = (this.pages > 1 && this.page < this.pages ) ? blogUrl(this.category, this.page + 1) : null
    return (
      <Layout location={this.props.location} containerClassName={`pb-5 blog-posts-page ${this.category ? this.category : ''} blog-${this.page}`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo } />
        {/*<nav className="container" ref={ (ref) => this.NavContainerRef = ref }>*/}
          {/*<div className="row">*/}
            {/*<div className="col-12 col-xl-9 mx-auto breadcrumbs-component-container mb-2">*/}
              {/*<button className="btn btn-toggle-breadcrumbs" onClick={this.handleBreadcrumbsTrigger}><span>...</span></button>*/}
              {/*<Breadcrumbs items={ this.breadcrumbs } location={ this.props.location }*/}
                           {/*className={`breadcrumbs-list-container ${!this.state.breadcrumbs_visible ? 'd-none' : ''}`}/>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</nav>*/}

        <StickyShare shareDialogTitle={this.seo.title} shareDialogText={this.seo.description} />

        <div className="featured-blog-posts text-center page-header bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 mx-auto">
                <h1 className="mb-2 mb-md-4 text-left">Our Blog</h1>
                <div className="col-12 col-lg-6 text-left p-0">Explore insights from Honest Paws on the CBD industry, your pet’s health, and more...</div>
                <div className="our-blog-container d-flex row">
                  <Carousel className="col-12 mt-3 d-lg-none p-0" controls={false} interval={3000}>
                    { this.featuredBlogPosts.map( ({ node }, index) => {
                      return <Carousel.Item className="d-lg-none" key={index}>
                        <PostCard
                          title={ node.title } url={`https://www.honestpaws.com/blog/${node.slug}`} image={ node.heroImage } shortDescription={ node.shortDescription }
                          category={ blogPostCategoryLabels[node.category] }
                          key={ index }
                          version={2}
                          className="col-12 col-lg-4 my-4 p-0" />
                      </Carousel.Item>
                    })}
                  </Carousel>

                  { this.featuredBlogPosts.map( ({ node }, index) => {
                    return <PostCard
                      title={ node.title } url={`https://www.honestpaws.com/blog/${node.slug}`} image={ node.heroImage } shortDescription={ node.shortDescription }
                      category={ blogPostCategoryLabels[node.category] }
                      key={ index }
                      version={2}
                      className="col-4 my-4 d-none d-lg-flex" />
                  })}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="explore-categories text-center page-header bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 mx-auto row">
                <div className="col-12 col-lg-6 p-0">
                  <h2 className="text-primary text-left">Explore Categories</h2>
                  <div className="text-left my-4">Want to discover articles, but don’t know where to start? <br className="d-none d-lg-block"/> Check out our categories.</div>
                </div>
                <div className="col-lg-6 d-none d-lg-flex justify-content-end explore-book-image">
                  <img src="/img/blog/D-Explore-Book-Image.svg" alt="Explore Book Image"/>
                </div>
                <div className="row categories-container d-none d-lg-flex">
                  {this.allCategories.map( (category, index) => {
                    const link = "https://www.honestpaws.com/blog/"+kebabCase(category)

                    return <div key={ index } className="col-lg-4 my-2 text-left">
                      <a target="_top" href={ link } className="card-link">
                        <Card>
                          <Card.Body>
                            <Card.Title as="div">
                              <div className="category-title text-graydark d-flex align-items-center justify-content-start">{ blogPostCategoryLabels[category] }</div>
                            </Card.Title>
                            <div className="category-description mt-3">{blogPostCategoryDescriptions[category]}</div>
                          </Card.Body>
                          <Card.Footer className="text-right">
                            <div><FaArrowRight title="Arrow Right"/></div>
                          </Card.Footer>
                        </Card>
                      </a>
                    </div>
                  })}
                </div>

                <div className="categories-container-mobile d-lg-none">
                  <ul className="categories-list">
                    { this.allCategories.map((category , i) => {
                      const link = "https://www.honestpaws.com/blog/"+kebabCase(category)

                      return (
                        <li className="category col-10 pl-0" key={i} >
                          <div className="col-12 my-2 text-left p-0">
                            <Card>
                              <Card.Body>
                                <Card.Title as="div">
                                  <a href={ link } target="_top" className="category-title text-graydark d-flex align-items-center justify-content-start">{ blogPostCategoryLabels[category] }</a>
                                </Card.Title>
                                <div className="category-description mt-3">{blogPostCategoryDescriptions[category]}</div>
                              </Card.Body>
                              <Card.Footer className="text-right">
                                <a href={ link } target="_top"><FaArrowRight title="Arrow Right"/></a>
                              </Card.Footer>
                            </Card>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="col-12 d-lg-none">
                  <img src="/img/blog/D-Explore-Book-Image.svg" alt="Explore Book Image"/>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="subscribe-and-about text-left bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 mx-auto">
                <div className="subscribe-container px-3 py-5 p-lg-6 d-lg-flex row m-0">
                  <div className="col-12 col-lg-6 p-0">
                    <h3 className="text-primary">Be The First To Know</h3>
                    <div className="description">The lates articles, straight to your inbox.</div>
                  </div>
                  <div className="col-12 col-lg-6 pl-lg-4 mt-4 mt-lg-0 p-0 d-flex align-items-center">
                    <input type="text" placeholder="jane@gmail.com" id='customer_email' value={this.state.customer_email} onChange={this.handleCustomerEmail} />
                    <button className="btn btn-primary text-white" onClick={this.subscribeToKlaviyoList} disabled={this.state.btn_disabled}>SUBSCRIBE</button>
                  </div>
                  <div className="col-12 col-lg-6 pl-lg-4 ml-auto mt-3 mt-lg-0 p-0">
                    <small className={`${this.state.show_success_message ? 'd-block' : 'd-none'} text-success`} >Subscribed!</small>
                  </div>
                </div>

                <div className="about-content-container row px-0 py-6">
                  <div className="col-12 col-lg-8 mb-4">
                    <h3 className="text-primary mb-3">About Honest Paws</h3>
                    <p>Our mission is to provide premium quality CBD products that help support the health and happiness of the animals we call family.</p>
                    <p>Discover our collection of CBD products today, including <a target="_top" href="https://www.honestpaws.com/collections/cbd-for-dogs/">CBD Oil for Dogs</a>, <a target="_top" href="https://www.honestpaws.com/collections/cbd-dog-treats/">CBD Dog Treats</a>, and <a target="_top" href='https://www.honestpaws.com/products/cbd-oil-for-cats/'>CBD Oil for Cats</a>.</p>
                  </div>
                  <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                    <img src="/img/honestpaws-logo-circle.png" alt="Honest Paws Logo"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostsPageTemplate

export const BlogPostsPageQuery = graphql`
  query BlogPostsPageQuery {
    site {
      siteMetadata { 
        siteUrl,
        contentfulNoIndexBlogs
      }
    }
    featuredBlogPosts: allContentfulBlogPost(filter: {featuredBlogPost: {eq: true}}) {
      edges {
        node {
          ...contentfulBlogPost
        }
      }
    }
  }
`